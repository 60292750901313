import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));


// loadScript('assets/scripts/iframeResizer.contentWindow.min.js')
// .then(() => {
//   platformBrowserDynamic()
//     .bootstrapModule(AppModule)
//     .catch((err) => console.error(err));
// })
// .catch((error) => console.error(`Error loading script: ${error.message}`));


// function loadScript(src: string): Promise<void> {
//   return new Promise((resolve, reject) => {
//     const scriptElement = document.createElement('script');
//     scriptElement.src = src;
//     scriptElement.onload = () => resolve();
//     scriptElement.onerror = () => reject(new Error(`Failed to load script: ${src}`));

//     document.head.appendChild(scriptElement);
//   });
// }